.home-page-photo {
  width: 75%;
  margin: 0;
}

.instagram-feed {
  width: 80%;
  display: flex;
  margin: 0 auto;
  .instagram-media {
    margin: 20px !important;
  }
}

.google-map-embed {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}