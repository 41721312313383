ul.tag-cloud {
  padding: 2em 0 3em;
  margin: 0;
  overflow: hidden;
  list-style: none;

  li {
    display: inline-block;
    margin-right: .5em;
    a.post-tag {
      border-radius: 0.2em;
    }
  }
}

.tag-list {
  .tag-group {
    margin-bottom: 2em;
    .tag-group-title {
      color: $warning;
    }

    .tag-item {
      padding-left: 1em;
      margin-bottom: .5em;
      a {
        color: $text-color;
        &:hover,
        &:focus {
          color: lighten($text-color, 24%);
        }
      }
    }
  }
}

.cat-list {
  .cat-group {
    margin-bottom: 2em;
    .cat-group-title {
      color: $warning;
      text-transform: capitalize;
    }

    .cat-item {
      padding-left: 1em;
      margin-bottom: .5em;
      a {
        color: $danger;
        &:hover,
        &:focus {
          color: lighten($danger, 24%);
        }
      }
    }
  }
}
